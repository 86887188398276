import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Màster Class de Casos Pràctics en directe (Específic Policies Locals 2024 - 19
                    maig 09:00 a 14:00 ZOOM)
                </Title>
                <Text>
                    Amb els últims casos pràctics oficials de les darreres convocatòries
                    <br />
                    <br />
                    A més vídeo, introducció d'1 hora i 30 minuts.
                    <br />
                    <br />
                    Us presentem el millor curs per preparar els casos pràctics de la prova pràctica
                    corresponent a la convocatòria de 2024.
                    <br />
                    <br />
                    Aquesta prova pràctica s'ha convertit en imprescindible i és superimportant
                    treure una bona nota.
                    <br />
                    <br />
                    I és que a iOpos Acadèmia som especialistes.
                    <br />
                    <br />
                    El dia de la connexió farem un repàs al marc legislatiu per tal de tenir clar
                    els conceptes a l'hora de redactar el cas pràctic.
                    <br />
                    <br />
                    Ens centrarem en els aspectes més importants, així com redactar correctament i
                    sobretot repassarem els casos pràctics oficials de les darreres oposicions amb
                    correccions en vers al tribunal qualificador.
                    <br />
                    <br />
                    La connexió en directe serà el diumenge 19 de maig d'abril de 9.00 a 14.00 h a
                    través de ZOOM i queda gravada fins el dia de l'examen
                    <br />
                    <br />
                    Per sols 39,99 euros
                    <br />
                    <br />
                    T'esperem!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/84U5T82_PZs"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
